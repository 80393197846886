<template>
  <div class="big-box">
    <div class="alot-box">
      <div class='alottext'>以AIot赋能，释放智慧生活无限潜力</div>
      <!-- 下面三个详情页 -->
      <div class='alot-san'>
        <div class='alot-sans-in' v-for="item in list" :key="item.id">
          <img :src="item.images" alt="">
          <span class="text">{{item.name}}</span>
          <span class="texts textY">{{item.text}}</span>
        </div>
        <!-- <span>
        <img src="" alt="">
        <span class="text">即开即用的平台</span>
        <span class="texts"></span>
      </span>
      <span>
        <img src="" alt="">
        <span class="text">智慧化的应用</span>
        <span class="texts"></span>
      </span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 组件名称
  name: "",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      list: [
        {
          id: 0,
          name: "强大的物联能力",
          images: require("../../assets/1a.png"),
          text: "久碳能源科技物联网平台支持不同协议的硬件及应用接入，简化开发难度，实现终端设备快速连接，数据统一融合贯通，促进场景快速落地。",
        },
        {
          id: 1,
          name: "即开即用的平台",
          images: require("../../assets/2a.png"),
          text: "通过久碳能源智慧平台，以“云-边-端”一体化能力预封装社区应用，提供包括通行、安全、宜居、便民、节能等模块，按需选择，开箱即用。",
        },
        {
          id: 2,
          name: "智慧化的应用",
          images: require("../../assets/3a.png"),
          text: "汇聚业务及设备数据，基于全栈场景AI和大数据平台能力，针对风险事件进行智能化决策和处理，可根据应用场景进行定制化算法调优训练。",
        },
      ],
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {},
  // 以下是生命周期钩子   注：没用到的钩子请自行删除
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {},
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {},
};
</script> 

<style scoped lang="less">
.big-box {
  display: flex;
  justify-content: center;
  background-color: hsl(218, 100%, 98%);
  .alot-box {
    // position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    width: 1200px;
    height: 557px;
    box-sizing: border-box;
    padding-top: 80px;
    // overflow: hidden;
    .alottext {
      width: 513px;
      height: 45px;
      font-size: 32px;
      padding-bottom: 41px;
      // position: absolute;
      // margin-left: 50%;
      // transform: translateX(-50%);
      // margin-top: 80px;
      // background-color: seagreen;
    }

    .alot-san {
      width: 1200px;
      height: 400px;
      display: flex;

      // position: absolute;
      // left: 360px;
      // top: 157px;
      // margin-top: 157px;
      // margin-left: 50%;
      // transform: translateX(-50%);
      overflow: hidden;
      .alot-sans-in {
        width: 400px;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 9px;
        box-sizing: border-box;
      }
      div {
        display: inline-block;
        width: 400px;
        height: 100%;
        // margin-top: 0;
        // background-color: rgb(131, 53, 53);
        img {
          width: 300px;
          height: 200px;
          // margin-top: 9px;
          // margin-left: 50px;
        }
        .text {
          display: inline-block;
          margin: 25px 0 16px 0;
          // width: 112px;
          // height: 22px;
          // margin-left: 144px;
          // margin-top: 25px;
          font-size: 16px;
          font-weight: 600;
        }
        .texts {
          display: block;
          width: 297px;
          height: 72px;
          font-size: 14px;
          line-height: 24px;
          font-weight: 100;
          // margin-left: 53px;
          // margin-top: 16px;
        }
        .textY {
          width: 294px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
</style>