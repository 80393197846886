<template>
  <div class="big-box">
    <div class="zhu-ying-box">
      <div class="zhu-ying">
        <div class="zhu-top">MAIN BUSINESS</div>
      </div>
      <div v-for="item in list" :key="item.id" class="pages">
        <div class="pagea1">
          <div>
            <span class="title-before">{{item.name}}</span>
            <span class="text-before">
              {{item.text}}
            </span>
          </div>
          <!-- <template> -->
          <div>
            <img :src="item.images" alt="" class="img-after">
          </div>
          <!-- </template> -->
        </div>

        <div class="pagea2">
          <div>
            <img :src="item.images1" alt="" class="img-after">
          </div>
          <div>
            <span class="title-before2">{{item.name1}}</span>
            <span class="text-before">
              {{item.text1}}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- <page-tools class="pagea3">
      <template v-slot:before>
        <div class="title-before3">数字社交</div>
        <span class="textbefore">
          。
        </span>
      </template>
      <template v-slot:after>
        <img src="../../assets/3b.png" alt="" class="ImgAfter">
      </template>
    </page-tools>

    <page-tools class="pagea4">
      <template v-slot:before>
        <img src="../../assets/4b.png" alt="" class="ImgAfter">
      </template>
      <template v-slot:after>
        <div class="title-before4">工业互联网平台</div>
        <span class="textbefore">
        </span>
      </template>
    </page-tools> -->
  </div>
</template>

<script>
// import PageTools from "@/components/PageTools";
export default {
  // 组件名称
  name: "",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {
    // PageTools,
  },
  // 组件状态值
  data() {
    return {
      list: [
        {
          id: 0,
          name: "数字园区",
          name1: "数字城市",
          images: require("../../assets/1b.png"),
          images1: require("../../assets/2b.png"),
          text: "运用新一代信息技术，以智能硬件为基础，数字化平台为载体，数字化运营为主线，以打造数字化产业的产业链为目标，推动和帮助中小微企业数字化转型升级，为入园的中小微企业提供便利的一站式服务，最终实现园区硬件智能化、管理平台化、企业数字化和产业生态化的“新四化”园区。",
          text1:
            "以政务云平台、大数据平台、公共支撑平台为依托，面向百姓、企业、政府等设计指尖民生解决方案、网上办事大厅解决方案、协同办公解决方案。从而推进信息资源整合和开发，统筹政府内外网，搭建全覆盖、高效能的数字城市体系，推动城市治理体系和治理能力的现代化、数字化。",
        },
        {
          id: 1,
          name: "数字社区",
          name1: "工业互联网平台",
          images: require("../../assets/1b.png"),
          images1: require("../../assets/2b.png"),
          text: "久碳能源智慧社区整体解决方案融合互联网、移动互联网和物联网技术，强化了社区中“人与人”的沟通，以及“物与物”的连接，构建智慧社区大数据运营平台。让社区更智能，实现“人流、车流”社区流量线上化，为物业公司守住流量资产，为流量变现提供保障。结合用户标签、商品标签应用，通过AI算法，推动行为分析、需求分析、购买欲分析，撮合交易，实现流量变现，提升智慧社区增值收入",
          text1:
            "通过腾讯云、千软通、一云通、德沃克等系统深度耦合，推动企业业务上云及数字化车间/工厂建设，帮助传统制造企业转型升级，实现数字化、网络化、智能化发展，提高企业内部生产效率，降低能源消耗和产品不良率，提升制造企业核心竞争力。",
        },
      ],
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {},
  // 以下是生命周期钩子   注：没用到的钩子请自行删除
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {},
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {},
};
</script> 

<style scoped lang="less">
.big-box {
  display: flex;
  justify-content: center;
  .zhu-ying-box {
    // position: relative;
    overflow: hidden;
    // left: 50%;
    // transform: translateX(-50%);
    // height: 1866px;
    width: 1043px;
    //   background-color: rgb(119, 96, 96);
    // padding-top: 240px;
    .zhu-ying {
      height: 249px;
      display: flex;
      align-items: center;
      justify-content: center;
      .zhu-top {
        // width: 336px;
        // height: 56px;
        background-color: #fff;
        position: relative;
        // left: 50%;
        // transform: translateX(-50%);
        // top: 125px;
        font-size: 40px;
        color: #e1ecff;
        letter-spacing: 1px;
        font-weight: 400;
        &::after {
          content: "主营业务";
          position: absolute;
          // width: 128px;
          // height: 45px;
          font-size: 31px;
          color: #333;
          font-weight: bold;
          bottom: 23px;
          left: 104px;
        }
      }
    }

    // .pagea1 {
    //   // margin-top: 88px;
    // }
    .title-before,
    .title-before2 {
      display: inline-block;
      // width: 80px;
      height: 28px;
      font-size: 20px;
      font-weight: 700;
      // background-color: #fff;
      margin-top: 57px;
      margin-bottom: 24px;
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      // display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      &::after {
        content: "";
        display: block;
        height: 5px;
        width: 100%;
        background-color: rgb(108, 106, 226);
        margin-top: -6px;
      }
    }

    // .title-before4 {
    //   width: 145px;
    //   margin-right: 251px;
    //   margin-top: 81px;
    // }
    .text-before {
      display: block;
      width: 397px;
      font-size: 14px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
    }
    .img-after {
      width: 406px;
      height: 300px;
      border: 1px dotted #333;
    }
    // .pagea2 {
    //   margin-top: 88px;
    // }
    // .pagea3 {
    //   margin-top: 125px;
    // }
    // .pagea4 {
    //   margin-top: 75px;
    // }
    // .pages,
    // .pagea2 {
    .pagea1,
    .pagea2 {
      width: 1043px;
      display: flex;
      justify-content: space-between;
    }
    .pagea2 {
      margin: 88px 0;
    }
    // .pagea2 {
    //   // width: 794px;
    //   display: flex;
    //   justify-content: space-between;
    // }
    // display: flex;
    // margin-top: 0;
    // display: flex;
    // justify-content: flex-end;
    // flex-direction: row-reverse;
    // align-items: flex-end;
    // flex-direction: column;
    // }
  }
}
</style>