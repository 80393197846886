<template>
  <div class="head">
    <div class="box">
      <div class="max-box">
        <div class="box-merge">
          <img src="@/assets/jiutan-logo.png" alt="久碳能源科技" class="img-shuxi">
          <div class="jiutan-title">
            <p>久碳能源</p>
            <p>JIUTAN ENERGY</p>
          </div>
          <!-- <ul class="nav">
      <li>
        <a>首页</a>
      </li>
      <li><a>找工作</a></li>
      <li><a>寻求人才</a></li>
      <li><a>会员专区</a></li>
      <li><a>会员专区</a></li>
      <li><a>会员专区</a></li>
    </ul> -->
          <ul class='tab'>
            <li class="tab-pane" :class="item.id === idx ? 'is-active':''" v-for="item in list" :key="item.id" @click="handleClick(item.id)">{{item.name}}</li>
          </ul>
        </div>
        <!-- <el-tabs @tab-click="handleClick" > -->
        <!-- <el-tab-pane v-for="item in list" :key="item.id" :label="item.name" class="is-active"></el-tab-pane> -->
        <!-- <el-tab-pane label="找工作" name="second"></el-tab-pane>
      <el-tab-pane label="寻求人才" name="third"></el-tab-pane>
      <el-tab-pane label="会员专区" name="fourth"></el-tab-pane>
      <el-tab-pane label="发布信息" name="xinxi"></el-tab-pane>
      <el-tab-pane label="久碳能源商城" name="shuxi"></el-tab-pane> -->
        <!-- </el-tabs> -->
        <div class='dengLu'>
          <a href="">注册 &nbsp;</a>
          <span> | </span>
          <a href="">&nbsp; 登录</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  // 组件名称
  name: "",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      idx: "0",
      list: [
        { id: "0", name: "首页" },
        { id: "1", name: "找工作" },
        { id: "2", name: "寻求人才" },
        { id: "3", name: "会员专区" },
        { id: "4", name: "发布信息" },
        { id: "5", name: "久碳能源商城" },
      ],
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    handleClick(id) {
      return
      this.idx = id;
      //    console.log(tab, event);
    },
  },
  // 以下是生命周期钩子   注：没用到的钩子请自行删除
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {},
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {},
};
</script>

<style scoped lang="less">
// * {
//   margin: 0;
//   padding: 0;
// }
.head {
  box-shadow: 0 -31px 88px 22px #999394;
  position: relative;
  position: fixed;
  background-color: #fff;
  // display: flex;
  // justify-content: center;
  width: 100%;
  height: 80px;
  // position: fixed;
  z-index: 999;
  .box {
    // position: fixed;
    position: absolute;
    // z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    height: 80px;
    // width: 100%;
    // min-width: 1200px;
    display: flex;
    justify-content: center;
    .max-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 1200px;
    }
    .el-tabs__nav-wrap {
      height: 62px;
      margin-left: -170px;
      &::after {
        content: none;
      }
    }
    .img-shuxi {
      left: 361px;
      top: 20px;
      width: 140px;
      height: 41px;
    }
    .el-tabs__nav {
      // left: 611px;
      line-height: 80px;
      .el-tabs__item {
        font-size: 16px;
      }
      .is-active {
        &::after {
          content: "";
          display: block;
          height: 2px;
          width: 16px;
          background-color: #2966ff;
          margin-top: 0px;
          margin-left: 50%;
          transform: translateX(-50%);
        }
      }
      .el-tabs__active-bar {
        display: none !important;
      }
    }

    .dengLu {
      // top: 30px;
      // left: 1480px;
      // width: 80px;
      // height: 20px;
      font-size: 14px;
      a {
        color: #666;
        text-decoration: none;
        &:hover {
          color: #2966ff;
        }
      }
    }

    // 手写tab
    .box-merge {
      display: flex;
      align-items: center;
      img {
        width: 50px;
        height: 50px;
      }
      .jiutan-title {
        width: 120px;
        font-weight: bold;
        // font-family: fangsong;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        p:first-child {
        font-size: 26px;
        }
        p:last-child {
          font-size: 12px;
        }
      }
      // height: 80px;
      .tab {
        display: flex;
        margin-left: 100px;
        min-width: 800px;
        .tab-pane {
          list-style: none;
          margin-right: 50px;
          font-size: 16px;
          &:hover {
            cursor: pointer;
            color: #2966ff;
          }
        }
        .is-active {
          position: relative;
          color: #2966ff;
          &::after {
            content: "";
            display: block;
            height: 2px;
            width: 16px;
            background-color: #2966ff;
            position: absolute;
            margin-top: 8px;
            margin-left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  //   .nav {
  //     list-style: none;
  //     position: absolute;
  //     display: flex;
  //     left: 611px;
  //     li {
  //       margin-right: 50px;
  //       margin-top: 29px;
  //       &:nth-child(1) {
  //         a {
  //           color: #2966ff;
  //         }
  //       }
  //       a {
  //         text-decoration: none;
  //         font-size: 16px;
  //         color: #333;
  //         &:hover {
  //           color: #2966ff;
  //           &::after {
  //             content: "";
  //             display: block;
  //             height: 2px;
  //             width: 16px;
  //             background-color: #2966ff;
  //             margin-top: 8px;
  //             margin-left: 50%;
  //             transform: translateX(-50%);
  //           }
  //         }
  //       }
  //     }
  //   }
}
// .is-active {
//   font-size: 16px;
//   &::after {
//     content: "";
//     display: block;
//     height: 2px;
//     width: 16px;
//     background-color: #2966ff;
//     margin-top: 0px;
//     margin-left: 50%;
//     transform: translateX(-50%);
//   }
// }
</style>
