<template>
  <div class="develop-target">
    <span class="development"></span>
    <p class='development-bom'>构建平台、资本、人才、技术四位一体的产业链和价值链</p>
    <!-- <div class="box-platform"> -->
    <!-- <div class="ulrs"> -->
    <ul class='uls'>
      <li v-for="item in list" :key="item.id">
        <img :src="item.images" alt="">
        <span>{{item.name}}</span>
      </li>
      <!-- <li>
          <img src="../../assets/c2.png" alt="">
          <span>资本</span>
        </li>
        <li>
          <img src="../../assets/c3.png" alt="">
          <span>人才</span>
        </li>
        <li>
          <img src="../../assets/c4.png" alt="">
          <span>技术</span>
        </li> -->
    </ul>
    <!-- </div> -->
    <!-- </div> -->

    <div class="xuxian"></div>
    <!-- 行业资讯 -->
    <div class="industry">
      <div class="industry-pa">
        <p class="industry-top">INDUSTRY NEWS</p>
        <p class="industry-top1">让您第一时间了解久碳能源的最新动态</p>

        <div class='content'>
          <img class="industry-1d-img" src="../../assets/1d.png" alt="">
          <div class="industry-dynamic">
            <div class="industry-dynamic-top">
              <span>久碳能源动态</span>
              <span>查看全部咨询→</span>
            </div>
            <div class="industry-dynamic-bottom">
              <div class="industry-dynamic-name" v-for="item in data" :key="item.id">
                <div class="industry-dynamic-one">
                  <span class="text-Fatal">{{item.ids}}</span>
                  <span class="text-one">{{item.name}}</span>
                  <span class="text-two">公司新闻 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2021/06/25 14:25</span>
                </div>
                <div>
                  <img :src="item.images" alt="">
                </div>
              </div>
              <!-- <div class="industry-dynamic-name">
              <div class="industry-dynamic-one">
                <span class="text-Fatal">02</span>
                <span class="text-one">新增业主缴纳物业费后同一房间其他住户的物业服务时间会同步更改 三个点</span>
                <span class="text-two">公司新闻 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2021/06/2514:25</span>
              </div>
              <div>
                <img src="../../assets/3d.png" alt="">
              </div>
            </div>
            <div class="industry-dynamic-name">
              <div class="industry-dynamic-one">
                <span class="text-Fatal">03</span>
                <span class="text-one">物业新增已缴总账单中的筛选条件增 加物业服务时间区间的筛选等优化</span>
                <span class="text-two">公司新闻 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2021/06/2514:25</span>
              </div>
              <div>
                <img src="../../assets/4d.png" alt="">
              </div>
            </div> -->
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- 最底部 -->
    <div class="lowestLevel">
      <div class="lowestLevel-con">
        <div class="shuXi-logo">
          <!-- <img src="../../assets/bottom.png" alt=""> -->
        </div>
        <div class="lowestLevel-the-pack">
          <div class="lowestLevel-the" v-for="item in ShuXi" :key="item.id">
            <span class="lowestLevel-SX">{{item.name}}</span>
            <ul class="lowestLevel-bottom-ul" v-for="(data,index) in item.list" :key="index">
              <li class="address-box" v-if="data.bor">
                <img :src="data.images" alt="" v-if="data.bor" style="margin-right: 9px;">
                <span v-if="!data.bors">{{data.name}}</span>
                <span  v-else style="display: block">{{data.name}}</span>
              </li>
              <li v-else>
                {{data}}
              </li>
            </ul>
          </div>
        </div>

        <!-- <div class="lowestLevel-the">
          <span class="lowestLevel-SX">网站导航</span>
          <ul class="lowestLevel-bottom-ul">
            <li>智慧园区</li>
            <li>智慧社区</li>
            <li>智慧安防</li>
            <li>智慧校园</li>
          </ul>
        </div>
        <div class="lowestLevel-the">
          <span class="lowestLevel-SX">法律政策</span>
          <ul class="lowestLevel-bottom-ul">
            <li>法律声明</li>
            <li>隐私政策</li>
            <li>条款我们</li>
            <li>廉政合规</li>
          </ul>
        </div>
        <div class="lowestLevel-the">
          <span class="lowestLevel-SX">联系我们</span>
          <ul class="lowestLevel-bottom-ul">
            <li>
              <img src="../../assets/ion1.png" alt="">
              久碳能源运营
            </li>
            <li>
              <img src="../../assets/icon2.png" alt="">
              748818@163.com
            </li>
            <li>
              <img src="../../assets/ioc3.png" alt="">
              15868547589
            </li>
            <li>
              <img src="../../assets/ion4.png" alt="">
              浙江省杭州市滨江区普沿街道伟业路<br />德信AI产业园C幢3楼324室
            </li>
          </ul>
        </div> -->
        <!-- <div class="lowestLevel-right">
          <span class="lowestLevel-SX-2">关注我们</span>
          <img src="../../assets/ewm.png" alt="">
        </div> -->
        <div class="solit">
          <span class="solit-copyright">{{ copyright }}</span>
          <div class="solit-text-center">
            <a class="solit-text-left" href='https://beian.miit.gov.cn/' target="_blank">浙ICP备20003998号-2</a>
             <a class="solit-text-left" href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802012061' target="_blank"><img src="../../assets/gh.png" alt=""> 浙公网安备 33010802012061号</a>
            <a class="solit-text-rifth" target="_blank" href="http://www.gsxt.gov.cn/index.html">
              <img src="../../assets/gh.png" alt="">
              电子营业执照
            </a>
          </div>
          <span class="solit-text-null"> </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 组件名称
  name: "",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      copyright: `Copyright ©️2018-${new Date().getFullYear()} 杭州久碳能源发展有限公司 版权所有`,
      texts: "",
      list: [
        {
          id: 0,
          name: "平台",
          images: require("../../assets/c1.png"),
        },
        {
          id: 1,
          name: "资本",
          images: require("../../assets/c2.png"),
        },
        {
          id: 2,
          name: "人才",
          images: require("../../assets/c3.png"),
        },
        {
          id: 3,
          name: "技术",
          images: require("../../assets/c4.png"),
        },
      ],
      data: [
        {
          id: 0,
          ids: "01",
          name: "小区新增各功能从其他小区一键复制 的功能等优化",
          images: require("../../assets/2d.png"),
        },
        {
          id: 1,
          ids: "02",
          name: "新增业主缴纳物业费后同一房间其他住户的物业服务时间会同步更改 三个点",
          images: require("../../assets/3d.png"),
        },
        {
          id: 2,
          ids: "03",
          name: "物业新增已缴总账单中的筛选条件增 加物业服务时间区间的筛选等优化",
          images: require("../../assets/4d.png"),
        },
      ],
      ShuXi: [
        {
          id: 0,
          name: "了解久碳能源",
          list: ["关于我们", "最新动态", "加入我们", "行业中心"],
        },
        {
          id: 1,
          name: "网站导航",
          list: ["智慧园区", "智慧社区", "智慧安防", "智慧校园"],
        },
        {
          id: 2,
          name: "法律政策",
          list: ["法律声明", "隐私政策", "条款我们", "廉政合规"],
        },
        {
          id: 3,
          name: "联系我们",
          list: [
            {
              id: 1,
              bor: true,
              name: "久碳能源",
              images: require("../../assets/ion1.png"),
            },
            {
              id: 2,
              bor: true,
              name: "garyxman@163.com",
              images: require("../../assets/icon2.png"),
            },
            {
              id: 3,
              bor: true,
              name: "18969904819",
              images: require("../../assets/ioc3.png"),
            },
            {
              id: 4,
              name: "浙江省杭州市滨江区长河街道滨康路352号1幢1号楼23层2307室",
              names: "浙江省杭州市滨江区长河街道滨康路352号1幢1号楼23层2307室",
              bor: true,
              bors: true,
              images: require("../../assets/ion4.png"),
            },
          ],
        },
      ],
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    Wrap() {
      this.texts = this.ShuXi[3].list[3].names;
      console.log(this.texts);
    },
  },
  // 以下是生命周期钩子   注：没用到的钩子请自行删除
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {
    // this.Wrap();
  },
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {},
};
</script>

<style scoped lang="less">
.develop-target {
  height: 400px;
  //   background-color: aqua;
  background-image: linear-gradient(
    to right,
    #275bd1,
    #4878e4,
    #5c8af1,
    #275bd1
  );

  //   overflow: hidden;
  position: relative;
  .development {
    position: relative;
    display: block;
    // width: 1200px;
    height: 56px;
    font-size: 40px;

    // left: 50%;
    // transform: translateX(-50%);
    top: 97px;
    &::before {
      content: "DEVELOPMENT GOALS";
      opacity: 0.2;
      color: #fff;
      font-size: 40px;
      display: flex;
      justify-content: center;
      // width: 128px;
      // height: 45px;
      font-weight: 500;
      color: #e1ecff;
      letter-spacing: 2px;
      // position: absolute;
      // left: 177px;
      // bottom: 28px;
    }
    &::after {
      content: "发展目标";
      color: #fff;
      font-size: 32px;
      // width: 128px;
      // height: 45px;
      font-weight: 600;
      // display: flex;
      // justify-content: center;
      position: absolute;
      top: -17px;
      transform: translateX(-50%);
      left: 50%;
      // left: 177px;
      // bottom: 28px;
    }
  }
  .development-bom {
    position: absolute;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    left: 50%;
    transform: translateX(-50%);
    top: 165px;
  }
  // .box-platform {
  // display: flex;
  // .ulrs {
  // margin-left: 50%;
  // transform: translateX(-50%);
  .uls {
    // width: 1200px;
    display: flex;
    justify-content: center;
    // width: 100%;
    margin-top: 200px;
    // align-items: center;
    // margin-left: -50%;
    // transform: translateX(-50%);
    li {
      list-style: none;
      width: 300px;
      height: 300px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      padding-top: 50px;
      box-sizing: border-box;
      img {
        width: 120px;
        height: 120px;
      }
      span {
        font-size: 20px;
        margin-top: 38px;
      }
    }
  }
  // }
  // }

  .xuxian {
    height: 90px;
    // background-color: #333;
    border-bottom: rgb(100, 96, 96) dashed 1px;
  }

  // 行业咨询
  .industry {
    height: 884px;
    // background-color: #275bd1;
    background-image: url("../../assets/dd.png");
    background-size: cover;
    display: flex;

    justify-content: center;
    .industry-pa {
      position: relative;
      // left: 50%;
      // transform: translateX(-50%);
      height: 100%;
      width: 1200px;
      // background-color: rgb(141, 131, 131);
      .industry-top {
        position: absolute;
        // width: 371px;
        // height: 56px;
        // background-color: rgb(151, 114, 114);
        color: #e1ecff;
        font-size: 40px;
        letter-spacing: 2px;
        top: 87px;
        // left: 775px;
        left: 50%;
        transform: translateX(-50%);

        &::after {
          content: "行业资讯";
          color: #333;
          font-size: 30px;
          // width: 128px;
          // height: 45px;
          font-weight: 600;
          position: absolute;
          left: 122px;
          bottom: 28px;
        }
      }
      .industry-top1 {
        position: absolute;
        font-size: 16px;
        line-height: 24px;
        top: 175px;
        left: 50%;
        transform: translateX(-50%);
      }
      .content {
        height: 500px;
        display: flex;
        margin-top: 264px;
        .industry-1d-img {
          width: 700px;
          height: 500px;
        }
        .industry-dynamic {
          position: absolute;
          right: 0;
          margin: 0 24px;
          // top: 264px;
          width: 452px;
          height: 500px;
          // background-color: rgb(206, 48, 48);
          .industry-dynamic-top {
            display: flex;
            justify-content: space-between;
            span {
              &:nth-child(1) {
                font-size: 16px;
                line-height: 24px;
              }
              &:nth-child(2) {
                color: #2966ff;
                font-size: 14px;
                line-height: 24px;
                // cursor: pointer;
                &:hover {
                  cursor: pointer;
                  color: rgb(2, 255, 226);
                }
              }
            }
          }
          .industry-dynamic-bottom {
            display: flex;
            margin-top: 24px;
            flex-direction: column;
            justify-content: space-between;
            height: 452px;
            .industry-dynamic-name {
              .industry-dynamic-one {
                cursor: pointer;
                position: relative;
                display: block;
                width: 230px;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                .text-Fatal {
                  position: absolute;
                  width: 74px;
                  height: 98px;
                  font-family: Impact;
                  font-size: 80px;
                  font-weight: 800;
                  color: #e1ecff;
                  z-index: -999;
                }
                .text-one {
                  font-size: 14px;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                }
                .text-two {
                  font-size: 12px;
                  color: #666;
                }
              }

              display: flex;
              justify-content: space-between;
              height: 120px;
              // background-color: #275bd1;
              &:nth-child(2) {
                padding: 24px 0;
                overflow: hidden;
                border-bottom: 1px dashed #333;
                border-top: 1px dashed #333;
              }
              div {
                &:nth-child(2) {
                  img {
                    height: 120px;
                    cursor: pointer;
                  }
                }
              }
              .solit {
                height: 60px;
                width: 100px;
                background-color: #515868;
              }
            }
          }
        }
      }
    }
  }

  // 最底部
  .lowestLevel {
    display: flex;
    justify-content: center;
    height: 400px;
    background-color: #1e2740;
    .lowestLevel-con {
      position: relative;
      height: 100%;
      width: 1200px;
      // margin-left: 50%;
      // transform: translateX(-50%);
      // background-color: #2966ff;
      .shuXi-logo {
        width: 100px;
        height: 34px;
        // margin-top: 24px;
        margin: 24px 0 32px 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .lowestLevel-the-pack {
        display: flex;
      }
      .lowestLevel-the {
        // float: left;
        display: inline-block;
        margin-right: 160px;
        color: #fff;
        .lowestLevel-SX {
          display: inline-block;
          font-size: 16px;
          // margin-top: 65px;
          margin-bottom: 24px;
        }
        .lowestLevel-bottom-ul {
          list-style: none;
          font-size: 12px;
          li {
            display: inline-block;
            margin-bottom: 16px;
            &:hover {
              // span {
              color: #2966ff;
              cursor: pointer;
              // }
            }
            img {
              width: 16px;
              height: 16px;
              vertical-align: top;
            }
          }
          .address-box {
            display: flex;
            width: 250px;
          }
        }
      }
      .lowestLevel-right {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 156px;
        width: 110px;
        right: 0;
        top: 90px;
        color: #fff;
        font-size: 16px;
        // display: flex;
        // justify-content: flex-end;
        img {
          width: 110px;
          height: 110px;
        }
      }
    }
    .solit {
      width: 1200px;
      height: 60px;
      // background-color: #fff;
      line-height: 60px;
      border-top: 1px solid rgb(82, 74, 74);
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      .solit-copyright {
        flex: 1;
        font-size: 12px;
        color: #fff;

      }
      .solit-text-center {
        font-size: 12px;
        display: inline;
        // background-color: #275bd1;
        color: #fff;
          img {
            vertical-align: middle;
            width: 18px;
            height: 17px;
            margin-left: 12px;
          }
        .solit-text-left{
          text-decoration:none;
          color: #fff;
          cursor: pointer;
        }
        .solit-text-rifth {
          text-decoration:none;
          color: #fff;
          cursor: pointer;

        }
      }

      .solit-text-null {
        flex: 1;
      }
    }
  }
}
</style>
