/*
 * @Description:
 * @Date: 2021-10-27 16:22:35
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/h",
    name: "Home",
    component: Home
  },
  {
    path: "/",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue")
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode:'history',
  routes
});

export default router;
