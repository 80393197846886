<template>
  <!-- <div class="box"> -->
  <div class="slider">
    <!-- <span class="demonstration">默认 Hover 指示器触发</span> -->
    <el-carousel arrow="always" :height="banner+'px'">
      <el-carousel-item v-for="item in imgList" :key="item.id">
        <!-- <el-image :src='require("../../assets/lunbotu.webp")' class="imas"></el-image> -->
        <div class="in-image">
          <div class="carousel-turi">
            <span class="contact datas">—— 数据 · 连接 · 赋能 ——</span>
            <span class="contact new-era">引领智能新时代</span>
            <button class="contact in-image-buttons">联系我们</button>
          </div>
          <img :src="item.idView" ref="banners" style="width:100%;" alt="" class="imas">
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
  <!-- </div> -->

</template>

<script>
export default {
  // 组件名称
  name: "",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      banner: "",
      imgList: [
        { id: 0, idView: require("../../assets/lunbotu.webp") },
        { id: 1, idView: require("../../assets/lunbotu.webp") },
        { id: 2, idView: require("../../assets/lunbotu.webp") },
      ],
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    imgLists() {
      this.$nextTick(() => {
        console.log(1);
        this.banner = (document.body.clientWidth * 1) / 4;
        console.log(this.banner);
      });
    },
  },
  // 以下是生命周期钩子   注：没用到的钩子请自行删除
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {
    // this.imgLists();
  },
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {},
};
</script>




<style scoped lang="less">
/deep/.el-carousel__container {
  height: 600px !important;
  width: 100%;
  margin-top: 80px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
/deep/.el-carousel__arrow--left {
  left: 40px;
  border: 2px solid;
  .el-icon-arrow-left {
    &::before {
      font-size: 23px;
    }
  }
}
/deep/.el-carousel__arrow--right {
  right: 40px;
  border: 2px solid;
  .el-icon-arrow-right {
    &::before {
      font-size: 23px;
    }
  }
}
.in-image {
  position: relative;
  height: 600px;
  .carousel-turi {
    position: absolute;
    height: 554px;
    width: 100%;
    // background-color: #444;
    display: flex;
    flex-direction: column;
    align-items: center;
    .contact {
      color: #fff;
    }
    .datas {
      font-size: 60px;
      margin-top: 162px;
    }
    .new-era {
      font-size: 20px;
      margin: 33px 0 48px 0;
      display: inline-block;
      width: 312px;
      text-align-last: justify;
    }
    .in-image-buttons {
      width: 200px;
      height: 44px;
      font-size: 16px;
      background-color: #2966ff;
      // color: #fff;
      border-radius: 22px;
      border: 0;
      &:hover {
        background-color: rgb(122, 154, 241);
        cursor: pointer;
      }
    }
  }
  .imas {
    // position: absolute;
    height: 100%;
  }
}
</style>



